@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .menu-button {
        @apply text-gray-900 dark:text-gray-50 hover:bg-gray-200 dark:hover:bg-gray-800 block px-3 py-2 rounded-md font-serif font-semibold text-base text-center;
    }

    .sub-menu-button {
        @apply text-gray-900 dark:text-gray-50 hover:bg-gray-200 dark:hover:bg-gray-800 block ml-2 px-3 py-2 rounded-md text-sm text-center font-medium;
    }

    .rdt_TableCol_Sortable > div,
    .rdt_TableCol > div {
        white-space: normal;
        font-weight: 600;
        text-overflow: ellipsis;
        overflow: visible;
    }

    .rdt_TableCol_Sortable {
        @apply hover:!text-red dark:hover:!text-yellow;
    }

    .rdt_TableHeadRow {
        @apply pr-2;
    }
}

@layer utilities {
    .content-area {
        min-height: calc(100vh - theme('spacing.40'));
    }

    .mobile-menu {
        height: calc(100vh - theme('spacing.16'));
    }

    .heroicon-stroke-w-0\.5 > path {
        stroke-width: 0.5;
    }

    .heroicon-stroke-w-1 > path {
        stroke-width: 1;
    }

    .heroicon-stroke-w-1\.5 > path {
        stroke-width: 1.5;
    }

    .heroicon-stroke-w-2 > path {
        stroke-width: 2;
    }

    .heroicon-stroke-w-2\.5 > path {
        stroke-width: 2.5;
    }

    .heroicon-stroke-w-3 > path {
        stroke-width: 3;
    }
}

@layer base {
    * {
        @apply box-border;
        /* Scrollbars for Firefox */
        scrollbar-width: thin;
        scrollbar-color: theme('colors.gray.300');
    }

    h1 {
        @apply text-4xl;
    }

    h2 {
        @apply text-3xl;
    }

    h3 {
        @apply text-2xl;
    }

    h4 {
        @apply text-xl;
    }

    h5 {
        @apply text-lg;
    }

    h6 {
        @apply text-base;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    th {
        @apply font-sans;
    }

    p,
    li,
    td {
        @apply font-serif;
    }

    /* Scroll Bars */
    *::-webkit-scrollbar {
        width: 6px;
    }

    *::-webkit-scrollbar-track {
        @apply bg-gray-50 dark:bg-gray-900;
    }

    *::-webkit-scrollbar-thumb {
        @apply bg-gray-300 dark:bg-gray-700 rounded-3xl;
    }

    @font-face {
        font-family: 'aktiv-grotesk';
        src: url('https://use.typekit.net/af/ab3e12/000000000000000077359d4f/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
                format('woff2'),
            url('https://use.typekit.net/af/ab3e12/000000000000000077359d4f/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
                format('woff'),
            url('https://use.typekit.net/af/ab3e12/000000000000000077359d4f/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
                format('opentype');
        font-display: auto;
        font-style: normal;
        font-weight: 400;
    }

    @font-face {
        font-family: 'aktiv-grotesk';
        src: url('https://use.typekit.net/af/951aca/000000000000000077359d51/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
                format('woff2'),
            url('https://use.typekit.net/af/951aca/000000000000000077359d51/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
                format('woff'),
            url('https://use.typekit.net/af/951aca/000000000000000077359d51/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
                format('opentype');
        font-display: auto;
        font-style: italic;
        font-weight: 400;
    }

    @font-face {
        font-family: 'aktiv-grotesk';
        src: url('https://use.typekit.net/af/a798a9/000000000000000077359d55/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
                format('woff2'),
            url('https://use.typekit.net/af/a798a9/000000000000000077359d55/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
                format('woff'),
            url('https://use.typekit.net/af/a798a9/000000000000000077359d55/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
                format('opentype');
        font-display: auto;
        font-style: normal;
        font-weight: 700;
    }

    @font-face {
        font-family: 'aktiv-grotesk';
        src: url('https://use.typekit.net/af/8e3d9f/000000000000000077359d58/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
                format('woff2'),
            url('https://use.typekit.net/af/8e3d9f/000000000000000077359d58/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
                format('woff'),
            url('https://use.typekit.net/af/8e3d9f/000000000000000077359d58/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
                format('opentype');
        font-display: auto;
        font-style: italic;
        font-weight: 700;
    }

    @font-face {
        font-family: 'aktiv-grotesk';
        src: url('https://use.typekit.net/af/63d81f/000000000000000077359d5a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
                format('woff2'),
            url('https://use.typekit.net/af/63d81f/000000000000000077359d5a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
                format('woff'),
            url('https://use.typekit.net/af/63d81f/000000000000000077359d5a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
                format('opentype');
        font-display: auto;
        font-style: normal;
        font-weight: 300;
    }

    @font-face {
        font-family: 'aktiv-grotesk';
        src: url('https://use.typekit.net/af/e4f1f2/000000000000000077359d5c/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3')
                format('woff2'),
            url('https://use.typekit.net/af/e4f1f2/000000000000000077359d5c/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3')
                format('woff'),
            url('https://use.typekit.net/af/e4f1f2/000000000000000077359d5c/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3')
                format('opentype');
        font-display: auto;
        font-style: italic;
        font-weight: 300;
    }

    @font-face {
        font-family: 'aktiv-grotesk';
        src: url('https://use.typekit.net/af/cbf647/000000000000000077359d61/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3')
                format('woff2'),
            url('https://use.typekit.net/af/cbf647/000000000000000077359d61/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3')
                format('woff'),
            url('https://use.typekit.net/af/cbf647/000000000000000077359d61/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3')
                format('opentype');
        font-display: auto;
        font-style: normal;
        font-weight: 800;
    }

    @font-face {
        font-family: 'aktiv-grotesk';
        src: url('https://use.typekit.net/af/af45c0/000000000000000077359d62/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3')
                format('woff2'),
            url('https://use.typekit.net/af/af45c0/000000000000000077359d62/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3')
                format('woff'),
            url('https://use.typekit.net/af/af45c0/000000000000000077359d62/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i8&v=3')
                format('opentype');
        font-display: auto;
        font-style: italic;
        font-weight: 800;
    }

    @font-face {
        font-family: 'aktiv-grotesk-condensed';
        src: url('https://use.typekit.net/af/739bc8/00000000000000003b9ad12a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
                format('woff2'),
            url('https://use.typekit.net/af/739bc8/00000000000000003b9ad12a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
                format('woff'),
            url('https://use.typekit.net/af/739bc8/00000000000000003b9ad12a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
                format('opentype');
        font-display: auto;
        font-style: normal;
        font-weight: 400;
    }

    @font-face {
        font-family: 'aktiv-grotesk-condensed';
        src: url('https://use.typekit.net/af/e25aad/00000000000000003b9ad12b/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
                format('woff2'),
            url('https://use.typekit.net/af/e25aad/00000000000000003b9ad12b/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
                format('woff'),
            url('https://use.typekit.net/af/e25aad/00000000000000003b9ad12b/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
                format('opentype');
        font-display: auto;
        font-style: italic;
        font-weight: 400;
    }

    @font-face {
        font-family: 'aktiv-grotesk-condensed';
        src: url('https://use.typekit.net/af/75d2b8/00000000000000003b9ad12e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
                format('woff2'),
            url('https://use.typekit.net/af/75d2b8/00000000000000003b9ad12e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
                format('woff'),
            url('https://use.typekit.net/af/75d2b8/00000000000000003b9ad12e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
                format('opentype');
        font-display: auto;
        font-style: normal;
        font-weight: 700;
    }

    @font-face {
        font-family: 'aktiv-grotesk-condensed';
        src: url('https://use.typekit.net/af/a9b64d/00000000000000003b9ad12f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
                format('woff2'),
            url('https://use.typekit.net/af/a9b64d/00000000000000003b9ad12f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
                format('woff'),
            url('https://use.typekit.net/af/a9b64d/00000000000000003b9ad12f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
                format('opentype');
        font-display: auto;
        font-style: italic;
        font-weight: 700;
    }

    @font-face {
        font-family: 'aktiv-grotesk-extended';
        src: url('https://use.typekit.net/af/e10446/00000000000000003b9ad13a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
                format('woff2'),
            url('https://use.typekit.net/af/e10446/00000000000000003b9ad13a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
                format('woff'),
            url('https://use.typekit.net/af/e10446/00000000000000003b9ad13a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
                format('opentype');
        font-display: auto;
        font-style: normal;
        font-weight: 400;
    }

    @font-face {
        font-family: 'aktiv-grotesk-extended';
        src: url('https://use.typekit.net/af/ce6561/00000000000000003b9ad13b/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
                format('woff2'),
            url('https://use.typekit.net/af/ce6561/00000000000000003b9ad13b/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
                format('woff'),
            url('https://use.typekit.net/af/ce6561/00000000000000003b9ad13b/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
                format('opentype');
        font-display: auto;
        font-style: italic;
        font-weight: 400;
    }

    @font-face {
        font-family: 'aktiv-grotesk-extended';
        src: url('https://use.typekit.net/af/3f03c1/00000000000000003b9ad13e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
                format('woff2'),
            url('https://use.typekit.net/af/3f03c1/00000000000000003b9ad13e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
                format('woff'),
            url('https://use.typekit.net/af/3f03c1/00000000000000003b9ad13e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
                format('opentype');
        font-display: auto;
        font-style: normal;
        font-weight: 700;
    }

    @font-face {
        font-family: 'aktiv-grotesk-extended';
        src: url('https://use.typekit.net/af/669c64/00000000000000003b9ad13f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
                format('woff2'),
            url('https://use.typekit.net/af/669c64/00000000000000003b9ad13f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
                format('woff'),
            url('https://use.typekit.net/af/669c64/00000000000000003b9ad13f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
                format('opentype');
        font-display: auto;
        font-style: italic;
        font-weight: 700;
    }

    @font-face {
        font-family: 'aktiv-grotesk-thin';
        src: url('https://use.typekit.net/af/099172/000000000000000077359d41/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3')
                format('woff2'),
            url('https://use.typekit.net/af/099172/000000000000000077359d41/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3')
                format('woff'),
            url('https://use.typekit.net/af/099172/000000000000000077359d41/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n2&v=3')
                format('opentype');
        font-display: auto;
        font-style: normal;
        font-weight: 200;
    }

    @font-face {
        font-family: 'aktiv-grotesk-thin';
        src: url('https://use.typekit.net/af/647e79/000000000000000077359d44/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i2&v=3')
                format('woff2'),
            url('https://use.typekit.net/af/647e79/000000000000000077359d44/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i2&v=3')
                format('woff'),
            url('https://use.typekit.net/af/647e79/000000000000000077359d44/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i2&v=3')
                format('opentype');
        font-display: auto;
        font-style: italic;
        font-weight: 200;
    }

    @font-face {
        font-family: 'yorkten-slab-condensed';
        src: url('https://use.typekit.net/af/7c952e/00000000000000007735bd43/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
                format('woff2'),
            url('https://use.typekit.net/af/7c952e/00000000000000007735bd43/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
                format('woff'),
            url('https://use.typekit.net/af/7c952e/00000000000000007735bd43/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
                format('opentype');
        font-display: auto;
        font-style: normal;
        font-weight: 400;
    }

    @font-face {
        font-family: 'yorkten-slab-condensed';
        src: url('https://use.typekit.net/af/bd1897/00000000000000007735bd47/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
                format('woff2'),
            url('https://use.typekit.net/af/bd1897/00000000000000007735bd47/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
                format('woff'),
            url('https://use.typekit.net/af/bd1897/00000000000000007735bd47/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
                format('opentype');
        font-display: auto;
        font-style: italic;
        font-weight: 400;
    }

    @font-face {
        font-family: 'yorkten-slab-condensed';
        src: url('https://use.typekit.net/af/ceac8c/00000000000000007735bd56/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
                format('woff2'),
            url('https://use.typekit.net/af/ceac8c/00000000000000007735bd56/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
                format('woff'),
            url('https://use.typekit.net/af/ceac8c/00000000000000007735bd56/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
                format('opentype');
        font-display: auto;
        font-style: normal;
        font-weight: 700;
    }

    @font-face {
        font-family: 'yorkten-slab-condensed';
        src: url('https://use.typekit.net/af/020682/00000000000000007735bd58/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
                format('woff2'),
            url('https://use.typekit.net/af/020682/00000000000000007735bd58/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
                format('woff'),
            url('https://use.typekit.net/af/020682/00000000000000007735bd58/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
                format('opentype');
        font-display: auto;
        font-style: italic;
        font-weight: 700;
    }

    @font-face {
        font-family: 'yorkten-slab-extended';
        src: url('https://use.typekit.net/af/b3e276/00000000000000007735bd44/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
                format('woff2'),
            url('https://use.typekit.net/af/b3e276/00000000000000007735bd44/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
                format('woff'),
            url('https://use.typekit.net/af/b3e276/00000000000000007735bd44/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
                format('opentype');
        font-display: auto;
        font-style: normal;
        font-weight: 400;
    }

    @font-face {
        font-family: 'yorkten-slab-extended';
        src: url('https://use.typekit.net/af/6cff3e/00000000000000007735bd46/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
                format('woff2'),
            url('https://use.typekit.net/af/6cff3e/00000000000000007735bd46/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
                format('woff'),
            url('https://use.typekit.net/af/6cff3e/00000000000000007735bd46/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
                format('opentype');
        font-display: auto;
        font-style: italic;
        font-weight: 400;
    }

    @font-face {
        font-family: 'yorkten-slab-extended';
        src: url('https://use.typekit.net/af/57d2b9/00000000000000007735bd55/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
                format('woff2'),
            url('https://use.typekit.net/af/57d2b9/00000000000000007735bd55/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
                format('woff'),
            url('https://use.typekit.net/af/57d2b9/00000000000000007735bd55/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
                format('opentype');
        font-display: auto;
        font-style: normal;
        font-weight: 700;
    }

    @font-face {
        font-family: 'yorkten-slab-extended';
        src: url('https://use.typekit.net/af/3721a6/00000000000000007735bd59/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
                format('woff2'),
            url('https://use.typekit.net/af/3721a6/00000000000000007735bd59/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
                format('woff'),
            url('https://use.typekit.net/af/3721a6/00000000000000007735bd59/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
                format('opentype');
        font-display: auto;
        font-style: italic;
        font-weight: 700;
    }

    @font-face {
        font-family: 'yorkten-slab-normal';
        src: url('https://use.typekit.net/af/02b240/00000000000000007735bd36/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n2&v=3')
                format('woff2'),
            url('https://use.typekit.net/af/02b240/00000000000000007735bd36/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n2&v=3')
                format('woff'),
            url('https://use.typekit.net/af/02b240/00000000000000007735bd36/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n2&v=3')
                format('opentype');
        font-display: auto;
        font-style: normal;
        font-weight: 200;
    }

    @font-face {
        font-family: 'yorkten-slab-normal';
        src: url('https://use.typekit.net/af/8b60e2/00000000000000007735bd39/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i2&v=3')
                format('woff2'),
            url('https://use.typekit.net/af/8b60e2/00000000000000007735bd39/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i2&v=3')
                format('woff'),
            url('https://use.typekit.net/af/8b60e2/00000000000000007735bd39/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i2&v=3')
                format('opentype');
        font-display: auto;
        font-style: italic;
        font-weight: 200;
    }

    @font-face {
        font-family: 'yorkten-slab-normal';
        src: url('https://use.typekit.net/af/9b3784/00000000000000007735bd42/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3')
                format('woff2'),
            url('https://use.typekit.net/af/9b3784/00000000000000007735bd42/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3')
                format('woff'),
            url('https://use.typekit.net/af/9b3784/00000000000000007735bd42/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3')
                format('opentype');
        font-display: auto;
        font-style: normal;
        font-weight: 400;
    }

    @font-face {
        font-family: 'yorkten-slab-normal';
        src: url('https://use.typekit.net/af/8c2f3e/00000000000000007735bd45/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i4&v=3')
                format('woff2'),
            url('https://use.typekit.net/af/8c2f3e/00000000000000007735bd45/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i4&v=3')
                format('woff'),
            url('https://use.typekit.net/af/8c2f3e/00000000000000007735bd45/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i4&v=3')
                format('opentype');
        font-display: auto;
        font-style: italic;
        font-weight: 400;
    }

    @font-face {
        font-family: 'yorkten-slab-normal';
        src: url('https://use.typekit.net/af/87c529/00000000000000007735bd4e/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n6&v=3')
                format('woff2'),
            url('https://use.typekit.net/af/87c529/00000000000000007735bd4e/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n6&v=3')
                format('woff'),
            url('https://use.typekit.net/af/87c529/00000000000000007735bd4e/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n6&v=3')
                format('opentype');
        font-display: auto;
        font-style: normal;
        font-weight: 600;
    }

    @font-face {
        font-family: 'yorkten-slab-normal';
        src: url('https://use.typekit.net/af/b4b599/00000000000000007735bd51/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i6&v=3')
                format('woff2'),
            url('https://use.typekit.net/af/b4b599/00000000000000007735bd51/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i6&v=3')
                format('woff'),
            url('https://use.typekit.net/af/b4b599/00000000000000007735bd51/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i6&v=3')
                format('opentype');
        font-display: auto;
        font-style: italic;
        font-weight: 600;
    }

    @font-face {
        font-family: 'yorkten-slab-normal';
        src: url('https://use.typekit.net/af/bc8dce/00000000000000007735bd54/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3')
                format('woff2'),
            url('https://use.typekit.net/af/bc8dce/00000000000000007735bd54/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3')
                format('woff'),
            url('https://use.typekit.net/af/bc8dce/00000000000000007735bd54/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3')
                format('opentype');
        font-display: auto;
        font-style: normal;
        font-weight: 700;
    }

    @font-face {
        font-family: 'yorkten-slab-normal';
        src: url('https://use.typekit.net/af/2ae4d5/00000000000000007735bd57/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i7&v=3')
                format('woff2'),
            url('https://use.typekit.net/af/2ae4d5/00000000000000007735bd57/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i7&v=3')
                format('woff'),
            url('https://use.typekit.net/af/2ae4d5/00000000000000007735bd57/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i7&v=3')
                format('opentype');
        font-display: auto;
        font-style: italic;
        font-weight: 700;
    }

    @font-face {
        font-family: 'yorkten-slab-normal';
        src: url('https://use.typekit.net/af/631308/00000000000000007735bd5a/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n8&v=3')
                format('woff2'),
            url('https://use.typekit.net/af/631308/00000000000000007735bd5a/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n8&v=3')
                format('woff'),
            url('https://use.typekit.net/af/631308/00000000000000007735bd5a/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n8&v=3')
                format('opentype');
        font-display: auto;
        font-style: normal;
        font-weight: 800;
    }

    @font-face {
        font-family: 'yorkten-slab-normal';
        src: url('https://use.typekit.net/af/709a07/00000000000000007735bd5d/30/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i8&v=3')
                format('woff2'),
            url('https://use.typekit.net/af/709a07/00000000000000007735bd5d/30/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i8&v=3')
                format('woff'),
            url('https://use.typekit.net/af/709a07/00000000000000007735bd5d/30/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i8&v=3')
                format('opentype');
        font-display: auto;
        font-style: italic;
        font-weight: 800;
    }
}
